import React, { useState } from 'react'
import { DialogProps } from '@radix-ui/react-dialog'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
} from '../ui/dialog'
import { Button } from '../ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form'
import { supabaseClient } from '@/lib/supabase'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Input } from '../ui/input'
import { toast } from 'sonner'

export type ForgotPasswordDialogProps = DialogProps

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({
  ...dialogProps
}: ForgotPasswordDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const closeDialog = () => dialogProps.onOpenChange?.(false)

  const formSchema = z.object({
    email: z.string().email(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  const resetPassword = async ({ email }: z.infer<typeof formSchema>) => {
    setIsSubmitting(true)

    try {
      const { data, error } = await supabaseClient.auth.resetPasswordForEmail(
        email,
        {
          redirectTo: `${window.location.origin}/reset-password`,
        },
      )

      console.log(data, error)

      if (error) {
        toast.error('Failed to send password reset email', {
          description: error.message,
        })
        closeDialog()
        return
      }

      toast.success('Password reset email sent successfully')
      closeDialog()
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog {...dialogProps} modal={true}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Password reset</DialogTitle>
          <DialogDescription>
            Enter your email to receive a password reset link
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            className="animate-in flex-1 flex flex-col w-full justify-center gap-4 text-foreground"
            onSubmit={form.handleSubmit(resetPassword, (errors) =>
              console.log(errors),
            )}
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs text-neutral-700 font-semibold">
                    Email
                  </FormLabel>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                className="mt-1"
              >
                Send password reset email
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default ForgotPasswordDialog
