'use client'
import { ZodRawShape, z } from 'zod'
import { MIN_PASSWORD_LENGTH } from './conts'

export const PDF_ACCEPTED_FILE_TYPES = ['application/pdf']
export const PDF_MAX_UPLOAD_SIZE_MB = 3
export const PDF_MAX_UPLOAD_SIZE = PDF_MAX_UPLOAD_SIZE_MB * 1024 * 1024 // 3MB

export const passwordSchema = z.string().min(MIN_PASSWORD_LENGTH, {
  message: 'Password must be at least 8 characters.',
})

export const passwordResetSchema = (
  extended: ZodRawShape = {},
  optional = false,
) =>
  z
    .object({
      password: optional ? passwordSchema.optional() : passwordSchema,
      confirmPassword: optional ? passwordSchema.optional() : passwordSchema,
    })
    .extend(extended)
    .refine(
      ({ confirmPassword, password }) =>
        !!password ? password === confirmPassword : true,
      {
        message: "Passwords don't match",
        path: ['confirmPassword'], // path of error
      },
    )

export const pdfFileSchema = (
  typeof window === 'undefined'
    ? z.any()
    : z.instanceof(FileList, { message: 'Required' })
)
  .refine((files) => files?.length > 0 && files?.[0], 'Required')
  .refine(
    (files) => files?.[0] && PDF_ACCEPTED_FILE_TYPES.includes(files[0].type),
    'Must be a PDF',
  )
  .refine(
    (files) => files?.[0].size <= PDF_MAX_UPLOAD_SIZE,
    `Size must be less than ${PDF_MAX_UPLOAD_SIZE_MB}MB`,
  )
